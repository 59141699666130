<template>
  <div class="container fullScreen">
    <el-drawer
      size="50%"
      direction="rtl"
      :show-close="false"
      :withHeader="false"
      :visible.sync="visible">
      <layoutMenu @toggle="toggle"></layoutMenu>
    </el-drawer>
    <i class="icon-menu menu-btn" @click="toggle(true)"></i>
    <transition name="el-fade-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  import layoutMenu from "./menu"
  export default {
    name: "layout",
    components: {
      layoutMenu
    },
    data: () => ({
      visible: false,
    }),
    methods: {
      toggle(visible) {
        this.visible = visible
      }
    }
  }
</script>

<style scoped lang="scss">
  .container {
    .menu-btn {
      @include position($p: fixed, $r: 20px, $b: 30%, $z: 2);
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      background-color: #FFFFFF;
      box-shadow: 0 0 8px 0 rgb(154 156 163 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
    }
  }
</style>