<template>
  <el-menu
    router
    :default-active="$route.path"
    @select="select">
    <template v-for="route in routes">
      <el-menu-item
        :index="route.path"
        :route="{...route, query: $route.query}">
        <i class="el-icon-menu"></i>
        <span slot="title">{{route.meta.title}}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
  import routes from "@/router/routes"
  export default {
    name: "layout-menu",
    data: () => ({
      routes: routes[2].children
    }),
    methods: {
      select() {
        document.title = this.$route.meta.title
        this.$emit('toggle', false)
      }
    }
  }
</script>

<style scoped lang="scss">

</style>